<template>
	<div>
		<div class="header">
			<button class="icon" @click="handleClose">
				<i class="ic-16 ic-close"></i>
			</button>
		</div>

		<div class="contents-wrap">
			<div class="popup-graphic">
				<i class="ic-64 ic-lock"></i>
			</div>
			<div class="text-center">
				<p class="heading-3-b text-center mb-24">{{ maskingEmail }} 님</p>
				<p class="body-2-r text-center mb-24">
					인증페이지가 만료되었거나 유효하지 않습니다. <br />
					위 이메일로 인증 메일을 다시 받으시겠습니까?
				</p>
				<p v-if="isReSent" class="body-2-r">
					<em class="point"
						>인증 메일을 재전송했습니다.<br />
						인증 메일은 발송 시점으로부터 1시간 동안 유효합니다.</em
					>
				</p>
			</div>
		</div>
		<div class="btn-area group-btn">
			<button class="line" @click="handleClose">취소</button>
			<button class="solid" @click="resendMail">인증 메일 다시 보내기</button>
		</div>
	</div>
</template>

<script>
import { retryEmail } from '@/api/login';
import mixinsAlert from '@/components/common/mixinsAlert';
import { SEND_EMAIL_WAITING } from '@/temlplates';
import { masking } from '@/utils/utils';

export default {
	name: 'WelcomeFail',
	props: {
		email: String,
		loginId: String,
	},
	mixins: [mixinsAlert],
	data() {
		return {
			isReSent: false,
			waitOneMinute: false,
		};
	},
	computed: {
		maskingEmail() {
			let email = masking.email(this.email);
			return email;
		},
	},
	methods: {
		async resendMail() {
			if (!this.waitOneMinute) {
				const data = {
					loginId: this.loginId,
				};

				try {
					this.waitOneMinute = true;
					const response = await retryEmail(data);
					this.$toasted.show('인증 메일을 전송했습니다.');
					this.isReSent = true;
					setTimeout(this.clearTimer, 60000);
				} catch (e) {
					if (e.data.errorCode === 'ERROR208') {
						this.$emit('close');
						this.$toasted.show('이미 회원가입 메일 인증을 시도했습니다. 로그인해주세요.');
					} else {
						this.$toasted.show('인증 메일 전송에 실패했습니다.');
					}
				}
			} else {
				this.alert(SEND_EMAIL_WAITING);
			}
		},
		handleClose() {
			this.$emit('close');
		},
		clearTimer() {
			this.waitOneMinute = false;
		},
	},
};
</script>

<style scoped></style>
