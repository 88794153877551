<template>
	<div></div>
</template>

<script>
import mixinsAlert from '@/components/common/mixinsAlert';
import { SIGNUP_MODAL_STYLE } from '@/utils/constants';
import WelcomeFail from '@/components/login/WelcomeFail';

export default {
	name: 'SignUpFail',
	mixins: [mixinsAlert],
	created() {
		this.$router.push('/login');
		this.$modal.show(
			WelcomeFail,
			{ email: this.email, loginId: this.loginId },
			Object.assign({}, SIGNUP_MODAL_STYLE, { name: 'WelcomeFail', clickToClose: false }),
		);
	},
	data() {
		return {
			email: this.$route.query.email,
			loginId: this.$route.query.loginId,
		};
	},
	methods: {},
};
</script>

<style scoped></style>
